@import "variables";

.author-image {
  display: inline-block;
  height: 100px;
  width: 100px;
  border-radius: 100px;
  overflow: hidden;
  margin-left: -15px;
  border: 3px solid white; }

.author-images-wrapper {
  padding-left: 15px;
  margin-top: 10px;
  margin-left: -2px; }

.request-demo {
  .author {
    font-family: RobotoSlab,sans-serif;
    font-size: 14px;
    line-height: 22px;
    text-align: center; } }

.contact-cta {
  background-color: seashell !important;
  margin-top: 112px !important;
  box-shadow: 0 0 20px 1px rgba(0,0,0,0.2);

  .author {
    margin-left: 40px; }

  .text {
    padding-left: 30px;
    margin-left: 0;
    margin-top: 38px; }

  .col-author-images {
    padding-right: 10px; }

  h3 {
    font-size: 26px !important;
    margin-bottom: 10px !important;
    margin-top: 15px !important; }

  p {
    font-size: 20px;
    line-height: 28px !important;
    font-weight: bold; }

  .button {
    margin-top: 0;
    margin-left: 0;
    font-weight: bold;
    color: white;
    background-color: $contact-color;
    border-color: $contact-color; } }

.request-demo {
  margin-top: 160px;
  margin-bottom: 160px;

  background-color: $default-gray-background-color;
  padding: 24px 28px 28px 28px;

  max-width: 350px;
  border-radius: 5px;

  form {
    margin-top: 8px;
    margin-bottom: 8px; }

  h3 {
    font-size: 28px;
    line-height: 36px;

    margin-top: 0;
    margin-bottom: 24px; }

  h3+p,
  .submit-input-field {
    margin-bottom: 48px; }

  .submit-input-field {
    margin-top: 18px; }

  ul {
    list-style-type: none;

    li {
      //color: white

      p {
        font-size: 20px;
        line-height: 44px;
        margin-left: 10px; }

      &:after {
        content: ' ';
        margin-left: 8px;
        width: 20px;
        height: 44px;
        background: url(/images/icons/request-demo/icon_check_black.svg) no-repeat center;
        background-size: 16px; }

      p, &:after {
        display: inline-block;
        vertical-align: middle; } } } }

@media (min-width: 769px) {
  .request-demo {
    max-width: 450px; } }

@media (min-width: 992px) {

  .contact-cta {
    background-color: seashell !important;
    margin-top: 112px !important;

    .text {
      padding-left: 0;
      margin-left: -30px;
      margin-top: 0; }

    .col-author-images {
      padding-right: 10px; }

    p {
      font-size: 20px;
      line-height: 32px;
      font-weight: bold; }

    .button {
      margin-top: 40px; } }

  .request-demo {
    max-width: 1140px;

    h3+p {
      margin-bottom: 0; }

    form {
      display: flex;
      justify-content: flex-end;

      .email-input-field,
      .submit-input-field {
        margin: 0;
        flex: 0;

        &::placeholder {
          color: #ccc; } }

      .email-input-field input {
        height: 52px;
        width: 284px;
        border-width: 2px;
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &:focus {
          border-color: #f60;
          box-shadow: 0 0 0 transparent;
          outline: none;
          border-width: 2px; } } }

    ul {
      text-align: right;

      li {
        display: inline-block;
        margin-left: 10px;

        &:after {
          margin-left: 10px; }

        p {
          font-size: 16px;
          line-height: 28px; } } } } }


.help .request-demo {
  background-color: $help-color; }

.assist .request-demo {
  background-color: $assist-color; }

.contact .request-demo {
  background-color: $contact-color; }

.reply .request-demo {
  background-color: $reply-color; }

.chatbot .request-demo {
  background-color: $chatbot-color; }

.automator .request-demo {
  background-color: $automator-color; }
