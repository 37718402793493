@import "variables";
@import "mixins";
@import "request-demo";

@import "products/front-teaser";

.index {
  h5 {
    font-size: 1em; }
  h1 span {
      border-bottom: 5px solid $brand-color; }

  .stage.headline-text h1 {
    margin-bottom: 18px; }

  .stage.headline-text p {
    font-size: 22px;
    line-height: 28px;
    color: #B7B7B7; }


  .start-now-form {
    margin-top: 48px;
    margin-bottom: 48px;
    background: white;

    .button.primary {
      background-color: $contact-color;
      border-color: $contact-color; } }

  .features {
    margin-top: 60px;
    margin-bottom: 80px;

    .feature {
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;

      &:not(:last-of-type) {
        margin-bottom: 48px; }

      .feature-icon {
        height: 90px;
        width: 90px;
        flex: 0 0 90px;
        margin-right: 10px;
        padding: 10px 0;
        margin-top: -20px; }

      .feature-text {
        flex: 1; }

      h3 {
        font-size: 16px;
        margin-bottom: 0;
        font-family: "Roboto", Arial, sans-serif; } } }

  .customers {
    h2 {
      font-size: 26px;
      line-height: 38px;
      text-align: center;
      margin-top: 0;
      margin-bottom: 48px; }

    .customer-logo {
      height: 80px;
      max-width: 350px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 5px;
      padding-right: 5px; } }


  $product-names: contact help assist reply chatbot;
  .product-front-teaser {
    @each $product in $product-names {
      &.#{$product} {
        h3,
        h5,
        .button {
          color: map-get($products, $product);
          border-color: map-get($products, $product);
          margin-top: 22px; }

        .button:hover {
          background-color: map-get($products, $product);
          color: white; }
        h5 {
          font-size: 1em; } } } }

  .products {
    margin-top: 0;
    margin-bottom: 160px;

    h2 {
      font-size: 26px;
      line-height: 38px;
      text-align: center;
      margin-top: 0; }

    .teaser-text {
      text-align: center;
      font-weight: bold;
      margin-bottom: 50px;
      font-family: "Roboto",sans-serif;
      font-size: 20px;
      line-height: 32px;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto; }

    .contact.active {
      .button,
      .product-name,
      .product-short-description {
        color: $contact-color;
        border-color: $contact-color; }

      .button:hover {
        background-color: $contact-color;
        color: white; } }

    .help.active {
      .button,
      .product-name,
      .product-short-description {
        color: $help-color;
        border-color: $help-color; }

      .button:hover {
        background-color: $help-color;
        color: white; } }

    .assist.active {
      .button,
      .product-name,
      .product-short-description {
        color: $assist-color;
        border-color: $assist-color; }

      .button:hover {
        background-color: $assist-color;
        color: white; } }

    .reply.active {
      .button,
      .product-name,
      .product-short-description {
        color: $reply-color;
        border-color: $reply-color; }

      .button:hover {
        background-color: $reply-color;
        color: white; } }

    .chatbot.active {
      .button,
      .product-name,
      .product-short-description {
        color: $chatbot-color;
        border-color: $chatbot-color; }

      .button:hover {
        background-color: $chatbot-color;
        color: white; } }

    .product {
      padding: 20px;
      cursor: pointer;
      border-radius: $default-border-radius;
      background-color: #f8f8f8;

      &.open {
        box-shadow: $default-box-shadow;
        border-radius: $default-border-radius;
        background-color: #f8f8f8; }

      @include transition(box-shadow .2s linear);

      &:not(:last-of-type) {
        margin-bottom: 15px; }

      &:first-of-type {
        margin-top: -10px; }

      &:hover {
        background-color: #fff; }

      .product-icon {
        height: 64px;
        width: 64px;
        margin-right: 20px;
        float: left; }

      .product-name {
        font-weight: bold;
        margin-top: -6px; }

      .button {
        margin-top: 28px;
        max-width: 130px;
        padding: 0 20px; }

      .product-name,
      .product-short-description,
      .product-description {
        padding-left: 84px; }

      .product-name {
        margin-top: 1px; }

      .active {
        .product-short-description {
          margin-bottom: 20px; } }

      .button {
        margin-left: 84px; }

      .product-description,
      .button {
        display: none; }

      &:hover,
      &.active {
        box-shadow: $default-box-shadow; }

      &.active {
        background-color: white;
        padding: 20px;
        margin-left: 0;
        margin-bottom: 18px;

        .product-short-description {
          display: block;
          margin-bottom: 20px; }

        .product-description,
        .button {
          display: block; }

        a {
          div {
            max-width: 200px; } } } } }


  .world-wide {
    margin-top: 160px;
    margin-bottom: 160px;

    h2 {
      font-size: 26px;
      line-height: 38px;
      text-align: center;
      margin-top: 0;
      margin-bottom: 48px; }

    .testimonial {
      text-align: center;
      margin-bottom: 80px;

      .author-image {
        display: inline-block;
        height: 100px;
        width: 100px;
        border-radius: 100px;
        overflow: hidden; }

      blockquote {
        margin-top: 28px;
        margin-bottom: 48px;
        color: #9800B2; }

      .author {
        display: flex;
        align-items: center;
        justify-content: center;

        .author-logo {
          margin-right: 18px;

          img {
            height: 50px;
            transform: scale(1.6); } }

        .author-name {
          text-align: left;
          font-family: RobotoSlab, sans-serif;
          font-size: 14px;
          line-height: 22px; } } }

    .information {
      text-align: center;

      .information-image {
        height: 92px;
        width: 130px;
        display: inline-block; }

      .button {
        margin: 48px auto;
        display: inline-block;
        border: 2px solid $assist-color;
        color: $assist-color; } } }

  .vision-preview {
    margin-top: -80px;
    margin-bottom: 160px;

    background-image: url("../images/backgrounds/vision-box.jpg");
    background-size: cover;
    background-position: left;
    padding: 24px 28px 28px 28px;
    border-radius: 5px;
    color: white;

    height: 420px;

    .row {
      width: 100%; }

    h3 {
      line-height: 28px; }

    .button {
      border-color: white;
      color: white;

      &:hover {
        background-color: white;
        color: $brand-color; } } } }


@media (max-width: 450px) {
  h1 {
    font-size: 32px; }

  .stage {
    &.headline-text {
      margin-bottom: 48px;
      margin-top: 80px; } }

  .products {
    padding: 0; }

  .request-demo {
    padding-top: 20px; }

  .start-now-form {
    margin-top: -20px !important; }

  .customers {
    .customer-logo {
      margin-bottom: 80px;

      img {
        transform: scale(1.1); } } }

  .feature-text {
    padding-right: 0;
    font-size: 18px; }

  .customers {
    margin-top: 120px;
    margin-bottom: 60px; }

  .product-tiles {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 769px) {
  .vision-preview {
    max-width: 450px; } }

@media (max-width: 991px) {
  h1 {
    line-height: 52px;
    font-size: 32px; }

  h2 {
    font-size: 22px; }

  .stage {
    &.headline-text {
      margin-top: 100px; } }

  .customers {
    .customer-logo {
      margin-bottom: 48px; } }

  .customers {
    margin-top: 120px;
    margin-bottom: 60px; }

  .products {
    max-width: 990px;

    p {
      font-size: 16px;
      line-height: 26px; }

    .product-images {
      position: relative;
      height: 406px;
      width: 100%;
      padding-left: 0;

      .product-image {
        position: absolute;
        opacity: 0;

        width: 90%;

        border-radius: $default-border-radius;

        @include transition(opacity .5s ease-in-out);

        img {
          //height: 100%
          border-radius: $default-border-radius; }

        &.active {
          opacity: 1; }

        .product-screenshot {
          background: white;
          box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.16);

          margin-bottom: -25px;
          border-radius: 8px;

          overflow: hidden; }

        &.reply {
          width: 70%;
          margin-left: auto;
          margin-right: auto;
          position: relative; } } } } }


@media (min-width: 992px) {
  .vision-preview {
    max-width: 1140px; }

  .feature-text {
    padding-right: 0; }

  h1 {
    line-height: 52px;
    font-size: 36px; }

  h2 {
    font-size: 22px; }

  .index {
    .stage.headline-text {
      margin-bottom: 0;
      margin-top: 80px; }

    .start-now-form {
      margin-top: 28px;
      margin-bottom: 28px;

      form {
        justify-content: center; } }

    .features {
      margin-top: 0;
      margin-bottom: 200px;

      .feature {
        margin-bottom: 0 !important;
        padding-left: 30px;
        padding-right: 0;

        h3 {
          font-size: 16px;
          margin-bottom: 2px;
          font-family: "Roboto", Arial, sans-serif; } } }

    .customers {
      max-width: 1600px;
      margin-top: 160px;
      margin-bottom: 160px;

      .customer-logo {
        margin-bottom: 80px;
        min-width: 300px; } }

    .products {
      .product-images {
        position: relative;
        height: 406px;
        width: 100%;
        padding-left: 0;

        .product-image {
          position: absolute;
          opacity: 0;

          width: 90%;

          border-radius: $default-border-radius;

          @include transition(opacity .5s ease-in-out);

          img {
            //height: 100%
            border-radius: $default-border-radius; }

          &.active {
            opacity: 1; }

          .product-screenshot {
            background: white;
            box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.16);

            margin-bottom: -25px;
            border-radius: 8px;

            overflow: hidden; }

          &.reply {
            width: 70%;
            margin-left: auto;
            margin-right: auto;
            position: relative; } } } }

    .index .world-wide h2 {
      margin-bottom: 80px; } } }

@media (min-width: 1200px) {

  h2 {
    font-size: 26px; }

  .index {
    .stage.headline-text {
      margin-bottom: 48px;
      margin-top: 120px; }

    .start-now-form {
      margin-top: 48px;
      margin-bottom: 48px; } } }


@media (min-width: 1300px) {

  h1 {
    font-size: 42px;
    line-height: 62px; }

  .vision-preview {
    max-width: 1260px; }

  .container {
    &.products {
      max-width: 1260px; } } }
