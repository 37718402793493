.product-front-teaser {
  margin: 200px auto 48px auto;

  .hideme {
    opacity: 0; }

  .col-md-8 {
    padding-right: 0; }

  .product-details {
    text-align: center;

    h3 {
      margin-top: 22px;
      margin-left: -5px;
      padding-right: 5px; }

    a .button {
      margin-top: 22px; } }

  .product-icon {
    width: 130px;
    height: 92px;

    display: inline-block;

    margin-left: -5px;

    img {
      width: 100%;
      height: 100%; } }

  p {
    text-align: left;
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto; }

  .product-screenshot {
    height: 304px;
    margin-top: 0;

    img {
      max-width: 704px; } } }


@media (max-width: 480px) {
  .product-front-teaser {
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    margin-bottom: 0 !important;
    padding-top: 0 !important;

    .product-screenshot {
      max-height: 100%;
      margin-top: 20px; } } }


@media (min-width: 481px) {
  .product-front-teaser {
    margin: 100px auto 0;
    padding-bottom: 100px;

    &.last {
      margin-top: 220px; }

    .product-screenshot {
      max-height: 100%;
      max-width: 704px;
      margin-top: 20px;

      img {
        max-width: 668px; } } } }

@media (min-width: 992px) {
  .product-front-teaser {
    margin: 200px auto 0;
    padding-bottom: 150px;

    &.last {
      padding-bottom: 0; }

    .product-screenshot {
      max-height: 100%;
      max-width: 704px;
      margin-top: -48px;

      img {
        max-width: 704px; } } } }
